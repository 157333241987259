import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function PumpScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  const tankPLC = props.plcs?.plcs?.[locationEquipment1?.Tank?.plcId];
  const CV3PLC = props.plcs?.plcs?.[locationEquipment1?.CV3?.plcId];
  if (!props.locations?.locations?.[1]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <InfoControlRow>
            {/* <Weather base_drybulb="-" base_rh="-" base_wetbulb="-" summit_drybulb="-" summit_rh="-" summit_wetbulb="-" /> */}
            {/* <Totals title="Totals" statistic1="-" statistic2="-" statistic3="-" label1="Gpm" label2="Cfm" label3="Guns" /> */}
            <Info
              title="Building"
              statistic={locationEquipment1?.Other?.ioValues?.[IOValueKeys.buildingTemp]?.value}
              label={locationEquipment1?.Other?.ioValues?.[IOValueKeys.buildingTemp]?.ioValueType?.units}
            />
            <Info
              img={PLC}
              title={startCase(tankPLC?.name)}
              statistic={PLCStateRouter(tankPLC)}
              label=""
              color={PLCColorRouter(tankPLC, theme)}
            />
            <Info
              img={PLC}
              title={startCase(CV3PLC?.name)}
            // statistic={locationEquipment1?.CV3?.plc?.isEnabled ? 'Online' : 'Offline'}
              statistic={PLCStateRouter(CV3PLC)}
              label=""
            // color={locationEquipment1?.CV3?.plc?.isEnabled ? theme.onGreen : theme.offRed}
              color={PLCColorRouter(CV3PLC, theme)}
            />
            <Info
              title={startCase(locationEquipment1?.Other?.ioValues?.[IOValueKeys.emergencyStop]?.name)}
              statistic={locationEquipment1?.Other?.ioValues?.[IOValueKeys.emergencyStop]?.value ? 'Active' : 'Inactive'}
              label=""
              color={locationEquipment1?.Other?.ioValues?.[IOValueKeys.emergencyStop]?.value ? theme.alarm : 'white'}
            />
            <AlarmReset
              title="Alarm Groups"
              buttonText="Reset"
              alarmData={locationEquipment1?.Other}
              setIOValue={props.setIOValue}
            />
          </InfoControlRow>
        </BasicRow>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Water System">
            <InfoControlRow>
              <MobileStatTable
                title="Water System Metrics"
                stats={
                [
                  {
                    description: 'Water Flow Rate',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.waterFlowRate]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.waterFlowRate]?.ioValueType?.units,
                  },
                  {
                    description: 'Suction Water Pressure',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.suctionWaterPressure]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.suctionWaterPressure]?.ioValueType?.units,
                  },
                  {
                    description: 'Pump Speed',
                    stat: locationEquipment1?.P1?.ioValues[IOValueKeys.speed]?.value,
                    label: locationEquipment1?.P1?.ioValues[IOValueKeys.speed]?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Pressure',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.dischargePressure]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.dischargePressure]?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Temp',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.dischargeTemperature]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.dischargeTemperature]?.ioValueType?.units,
                  },
                  {
                    description: 'Zone 2 Flow Rate',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.line2WaterFlow]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.line2WaterFlow]?.ioValueType?.units,
                  },
                  {
                    description: 'Zone 3 Flow Rate',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.line3WaterFlow]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.line3WaterFlow]?.ioValueType?.units,
                  },
                ]
              }
              />
              {/* <AutoValveControl
                title="V001: City" // This is the From City valve
                binary
                valveData={locationEquipment1?.CV1}
                setIOValue={props.setIOValue}
                button1IOValueID={locationEquipment1?.CV1?.ioValues?.open?.id}
                button2IOValueID={locationEquipment1?.CV1?.ioValues?.close?.id}
                button1Text={language.open}
                button2Text={language.close}
              /> */}
              {/* <AutoValveControl
                title="V021: Tank" // This is the Tank valve
                binary
                valveData={locationEquipment1.CV21}
                setIOValue={props.setIOValue}
                button1IOValueID={locationEquipment1?.CV21?.ioValues?.open?.id}
                button2IOValueID={locationEquipment1?.CV21?.ioValues?.close?.id}
              /> */}
              <VFDPumpControl
                title="Pump 1 VFD"
                pumpData={locationEquipment1.P1}
                pidData={locationEquipment1.P1_PID}
                setIOValue={props.setIOValue}
                max="750"
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                }}
                readValues={[0, 1]}
              />
              {/* <AutoValveControl
                title="Zone 3 Valve"
                binary
                oneshot
                setIOValue={props.setIOValue}
                valveData={locationEquipment1?.CV3}
                button1IOValueID={locationEquipment1?.CV3?.ioValues[IOValueKeys.openClose]?.id}
                button2IOValueID={locationEquipment1?.CV3?.ioValues[IOValueKeys.openClose]?.id}
              /> */}
              <ToggleControl
                title="Source Control"
                button1Text="Tank"
                button2Text="City"
                ioData={locationEquipment1?.Other?.ioValues?.[IOValueKeys.cityTankMode]}
                setIOValue={props.setIOValue}
              />
            </InfoControlRow>
            <PumpsRowExtraSmall styles={styles.hiddenMobile}>
              {/* <PipePlaceholder hidden minWidth="294px"/> */}
              <div css={css`flex: 2; display: flex; height: 100%;`}>
                <div css={css`min-width: 294px; flex-grow: 1;`} />
                <PipePlaceholder pipeAbove="right" minWidth="0px" />
              </div>
              <Valve
                top
                horizontal
                controls
                controlsTop="53px"
                controlsLeft="-30px"
                nameTop="20px"
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'Moving',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.inTravel,
                }}
                IOValueKeys={{
                  open: 'open',
                  close: 'close',
                  opened: 'opened',
                  closed: 'closed',
                  moving: 'moving',
                }}
                yellowImageKey="Moving"
                valveData={locationEquipment1.CV21}
              />
              <MiddlePipeL />
              <TriplePipeFork />
            </PumpsRowExtraSmall>
            <PumpsRowMedium>
              <WaterTank
                waterTankData={locationEquipment1.Tank}
                valveData1={locationEquipment1.CV1} // From City valve
              // These are my manual valves that don't have real data yet
                valveData2={{
                  name: 'Valve', start: 0, amps: 0, ampsUnits: 'kw', hours: 24,
                }}
                valveData3={{
                  name: 'Valve', start: 0, amps: 0, ampsUnits: 'kw', hours: 24,
                }}
                valveData4={{
                  name: 'Valve', start: 0, amps: 0, ampsUnits: 'kw', hours: 24,
                }}
              />
              <PipePlaceholder horizontalBelow color={theme.pipeColors.water}>
                <Statistic
                  label={locationEquipment1?.Other?.ioValues[IOValueKeys.waterFlowRate]?.ioValueType?.units}
                  border
                  statistic={locationEquipment1?.Other?.ioValues[IOValueKeys.waterFlowRate]?.value}
                />
                <Statistic
                  label={locationEquipment1?.Other?.ioValues[IOValueKeys.suctionWaterPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment1?.Other?.ioValues?.[IOValueKeys.suctionWaterPressure]?.value}
                  alertText={locationEquipment1?.Other?.ioValues?.low_suction_pressure?.value ? language.lowSuctionPressure : ''}
                  alertTop="-25px"
                  alertLeft="-80px"
                />
              </PipePlaceholder>
              <div css={styles.flexRowBox}>
                <div css={css`width: 40px;`}>
                  <Pump
                    psi
                    componentMinWidth="35px"
                    temperatureAbove
                    percent
                    pipeBelow="left"
                    pipeAbove="right"
                    setIOValue={props.setIOValue}
                    pumpData={locationEquipment1.P1}
                    otherData={locationEquipment1.Other}
                    stateKey={{
                      0: language.off,
                      1: language.on,
                      2: language.warning,
                      3: language.alarm,
                    }}
                    stateColorKey={{
                      0: `${theme.secondary}`,
                      1: `${theme.onGreen}`,
                      2: `${theme.warning}`,
                      3: `${theme.alarm}`,
                    }}
                    IOValueKeys={{
                      start: 'start',
                      stop: 'stop',
                      started: 'run_feedback',
                      stopped: 'run_feedback',
                    }}
                    writeValues={[1, 1]}
                    readValues={[1, 0]}
                    alarms={[
                      locationEquipment1?.P1?.ioValues?.warning,
                      locationEquipment1?.P1?.ioValues?.fault,
                      locationEquipment1?.P1?.ioValues?.low_amps,
                      locationEquipment1?.P1?.ioValues?.master_alarm,
                    ]}
                    stats={[
                      {
                        stat: locationEquipment1?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                        label: locationEquipment1?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                      },
                      {
                        stat: locationEquipment1?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                        label: locationEquipment1?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                      },
                      {
                        stat: locationEquipment1?.P1?.ioValues?.speed?.value,
                        label: locationEquipment1?.P1?.ioValues?.speed?.ioValueType?.units,
                      },
                    ]}
                  />
                </div>
                <div css={[styles.hiddenMobile, css`width: 0px; margin-top: -3px;`]}>
                  <Statistic
                    border
                    float
                    statistic={locationEquipment1?.Other?.ioValues[IOValueKeys.dischargePressure]?.value}
                    label={locationEquipment1?.Other?.ioValues[IOValueKeys.dischargePressure]?.ioValueType?.units}
                  />
                </div>
                <PipePlaceholder minWidth="140px" horizontalAbove />
              </div>
              <DoublePipeFork
                valveData={locationEquipment1.CV3}
                firstStat={locationEquipment1?.Other?.ioValues[IOValueKeys.line3WaterFlow]?.value}
                firstLabel={locationEquipment1?.Other?.ioValues[IOValueKeys.line3WaterFlow]?.ioValueType?.units}
                secondStat={locationEquipment1?.Other?.ioValues[IOValueKeys.line2WaterFlow]?.value}
                secondLabel={locationEquipment1?.Other?.ioValues[IOValueKeys.line2WaterFlow]?.ioValueType?.units}
                color={theme.pipeColors.water}
              />
            </PumpsRowMedium>
          </LabeledContainer>
        </BasicRow>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Air System">
            <BasicRow>
              <MobileStatTable
                title="Air System Metrics"
                stats={
                [
                  {
                    description: 'Discharge Air Pressure',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.ioValueType?.units,
                  },
                  {
                    description: 'Zone 2 Flow Rate',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.line2AirFlow]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.line2AirFlow]?.ioValueType?.units,
                  },
                  {
                    description: 'Zone 2 Temperature',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.line2AirTemp]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.line2AirTemp]?.ioValueType?.units,
                  },
                  {
                    description: 'Zones 3 Flow Rate',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.line3AirFlow]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.line3AirFlow]?.ioValueType?.units,
                  },
                  {
                    description: 'Zones 3 Temperature',
                    stat: locationEquipment1?.Other?.ioValues[IOValueKeys.line3AirTemp]?.value,
                    label: locationEquipment1?.Other?.ioValues[IOValueKeys.line3AirTemp]?.ioValueType?.units,
                  },
                ]
              }
              />
              <PumpsRowSmall>
                <Compressor
                  compressorData={locationEquipment1.C1}
                  pipeAbove="right"
                  pipeSizeAbove="large"
                  controlPositionTop="-85px"
                  controlPositionLeft="75px"
                  styles="margin: 60px 0px 32px -94px;"
                  pipeColorAbove={theme.pipeColors.air}
                  pipeColorBelow={theme.pipeColors.air}
                  setIOValue={props.setIOValue}
                  IOValueKeys={{
                    start: 'enable',
                    stop: 'disable',
                    started: 'run_feedback',
                    stopped: 'run_feedback',
                  }}
                  writeValues={[1, 1]}
                  readValues={[1, 0]}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                  }}
                  stateColorKey={{
                    0: theme.secondary,
                    1: theme.onGreen,
                  }}
                  statuses={[
                    {
                      id: locationEquipment1?.C1?.ioValues?.status?.id,
                      status: locationEquipment1?.C1?.ioValues?.status?.value,
                      statusKey: {
                        0: '',
                        1: 'Power Up',
                        2: 'Faulted',
                        3: 'Ready',
                        4: 'Enabled',
                        5: 'Auto Enabled',
                        6: 'Waiting for Blowdown',
                        7: 'Prestart',
                        8: 'Start',
                        9: 'Pause',
                        10: 'Unloaded',
                        11: 'Loading',
                        12: 'Full Load',
                        13: 'Modulating',
                        14: 'Stopping',
                        15: 'Remote Unload',
                        16: 'Remote Stopping',
                        17: 'Faulting',
                        18: 'Unloading',
                        19: 'Remote Unloading',
                        20: 'Precool the Dryer',
                        21: 'Flush Water',
                        22: 'Fill Water',
                        23: 'Jog Water Pump',
                        24: 'Post-Run Fluid Pump',
                        25: 'Pre-Run Fluid Pump',
                        26: 'Post-Run Fluid Pump-Remote',
                        27: 'Post-Run Fluid Pump-Automatic',
                        28: 'Brownout',
                      },
                      statusColorKey: theme.statusColors,
                    },
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.C1?.ioValues?.current?.value,
                      label: locationEquipment1?.C1?.ioValues?.current?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.C1?.ioValues?.run_time?.value,
                      label: locationEquipment1?.C1?.ioValues?.run_time?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.C1?.ioValues?.internal_pressure?.value,
                      label: locationEquipment1?.C1?.ioValues?.internal_pressure?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.C1?.ioValues?.internal_temperature?.value,
                      label: locationEquipment1?.C1?.ioValues?.internal_temperature?.ioValueType?.units,
                    },
                  ]}
                />
                <AirTank />
                <AirTank />
                <PipePlaceholder horizontalBelow size="large" color={theme.pipeColors.air}>
                  <ConnectionIndicator title="To Shop" />
                  <Statistic
                    border
                    size="large"
                    label={locationEquipment1?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.ioValueType?.units}
                    statistic={locationEquipment1?.Other?.ioValues[IOValueKeys.dischargeAirPressure]?.value}
                  />
                </PipePlaceholder>
                <PipeFork
                  number={4}
                  startSize="large"
                  color={theme.pipeColors.air}
                  firstChild={(
                    <div css={styles.flexRow}>
                      <Statistic
                        border
                        float
                        statistic={locationEquipment1?.Other?.ioValues[IOValueKeys.line2AirFlow]?.value}
                        label={locationEquipment1?.Other?.ioValues[IOValueKeys.line2AirFlow]?.ioValueType?.units}
                      />
                      <Pipe
                        horizontal
                        color={theme.pipeColors.air}
                      />
                      <Statistic
                        border
                        float
                        statistic={locationEquipment1?.Other?.ioValues[IOValueKeys.line2AirTemp]?.value}
                        label={locationEquipment1?.Other?.ioValues[IOValueKeys.line2AirTemp]?.ioValueType?.units}
                      />
                    </div>
                )}
                  secondChild={(
                    <div css={styles.flexRow}>
                      <Statistic
                        border
                        float
                        statistic={locationEquipment1?.Other?.ioValues[IOValueKeys.line3AirFlow]?.value}
                        label={locationEquipment1?.Other?.ioValues[IOValueKeys.line3AirFlow]?.ioValueType?.units}
                      />
                      <Pipe
                        horizontal
                        color={theme.pipeColors.air}
                      />
                      <Statistic
                        border
                        float
                        statistic={locationEquipment1?.Other?.ioValues[IOValueKeys.line3AirTemp]?.value}
                        label={locationEquipment1?.Other?.ioValues[IOValueKeys.line3AirTemp]?.ioValueType?.units}
                      />
                    </div>
                )}
                />
              </PumpsRowSmall>
            </BasicRow>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

PumpScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

PumpScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PumpScreen);
